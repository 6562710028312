import React from 'react';
import '../css/Spotlight.css';
import UpcomingEventsImg from '../assets/webP/events.webp';



// const upcomingEvents = [
//     {
//         name: "Masterclass Whisky",
//         date: "21 FEB",
//         imageRef: upcomingWhisky
//     },
//     {
//         name: "Wijndegustatie Nocturne",
//         date: "24 APR",
//         imageRef: upcomingSaniComfort
//     },
//     {
//         name: "Wijndegustatie Italië",
//         date: "28 MEI",
//         imageRef: upcomingItalia
//     },
//     {
//         name: "Wijndegustatie Zuid-Afrika",
//         date: "3 JULI",
//         imageRef: upcomingSouthAfrica
//     }
// ]

class Spotlight extends React.Component {
    render() {
        return (
            <div className='spotlight__wrapper'>
                <div className='content__wrapper'>
                    <div className='upcoming__wrapper'>
                        <img src={UpcomingEventsImg} alt="Upcoming events" className='upcoming__image'/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Spotlight;
